import axios from 'axios'

export async function checkToken(tokenValue) {
  try {
    const url = process.env.VUE_APP_API_URL
    const token = 'Bearer ' + tokenValue
    const { data } = await axios.get(url + '/login/verify', {
      headers: {
        Authorization: token,
      },
    })

    return data
  } catch (error) {
  }
}

<script>
import axios from 'axios';

export default {
    name: "iconeEmpresa",
    components: { },
    data() {
        return {
            imagemUrl: null,
            check_imagem: false,
        }
    },


}
</script>

<template>
    <main>
        <img  v-if="check_imagem" @click="abrirCadastroEmpresa">
        <div v-else class="icon"><i class="fa-solid fa-building"></i>
        </div>
    </main>
</template>

<style scoped>
 main {
    /*
    display: flex;
    margin-top: 10px;
    width: 40%;
    aspect-ratio: 1/1;
    border: 1px solid green;
    border-radius: 50%;
    border-color: transparent;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    */
    cursor: pointer;
} 

img,
/* .icon {
    margin-top: 10px;
    text-align: center;
    border: transparent;
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid green;
    border-radius: 100%;
    background-color: white;
} */

img {
    overflow: hidden;
}

.icon {
    font-size: 1.5rem;
     justify-content: center;
     padding: 0px 14px;
     color: black;
}
</style>
<script>
export default {
  props: {
    type: String, 
    index: Number,
    display: Boolean, 
    total: Number, 
  },
  data() {
    return {
      DropdownOpen: false,
      dropdowns: {},
      enviarPadrao: [],
      enviarImagems: [],
      enviarTexto: [],
      
    }
  },
  methods: {
    togglePagesDropdown() {
      this.DropdownOpen = !this.DropdownOpen;
      this.$emit('update-display')
    },
    toggleDropdown(id) {
      this.DropdownOpen = false;
      this.dropdowns[id] = !this.dropdowns[id];
      this.$emit('selectedOpt', id, this.index);
      this.$emit('updateDataInArray', id, this.index);
    },
    filtrarEnvio() {
      this.enviarPadrao = this.enviarPorTipo(0); 
      this.enviarImagems = this.enviarPorTipo(1);
      this.enviarTexto = this.enviarPorTipo(2);  
    },
  },
  computed: {
    mostrarImagem() {
      return this.type === 'pivot-table' || this.type === 'table';
    },
    shouldOpenUp() {
    return this.index >= this.total * 0.75;
  },
  }
}
</script>

<template>
  <div class="menu-bar">
    <ul>
      <li v-if="display" class="all-style" @click="togglePagesDropdown">
        <div class="button-onclik">
          <i class="fa-solid fa-caret-down"></i>
        </div>
        <div v-if="DropdownOpen" :class="['dropdown-menu', { 'open-up': shouldOpenUp }]">
          <ul>
            <li>
              <a @click.stop="toggleDropdown(0)" :key="index">
                <i id="icons" class="fa-brands fa-pied-piper-pp"></i>Padrão
              </a>
            </li>
            <li v-if="mostrarImagem">
              <a @click.stop="toggleDropdown(1)" :key="index">
                <i id="icons" class="fa-solid fa-image"></i>Imagem
              </a>
            </li>
            <li>
              <a @click.stop="toggleDropdown(2)" :key="index">
                <i id="icons" class="fa-solid fa-font"></i>Texto
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

  
<style scoped>
:root {
  --color-primary: #0073ff;
  --color-whitee: #100f0f;
  --color-black: #141d28;
  --color-black-1: #212b38;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

p {
  font-size: 17px;
}

#icons {
  color: #545556;
  font-size: 15px;
  margin-right: 5px;
  color: #2bb542;
}

#icon-open {
  font-size: 15px;
  margin-left: 45px;
}

#icon-openn {
  font-size: 15px;
  margin-left: 50px;
}

.button-onclik {
  color: black;
  font-size: 0.6rem;
}

.menu-bar {
  background-color: var(--color-black);
  height: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5 10%;
  position: relative;
}

.menu-bar ul {
  list-style: none;
  display: flex;
}

.all-style {
  border: none;
}

ul li {
  border: 1px solid rgba(185, 184, 184, 0.747);
}

.menu-bar ul li {
  position: relative;
  color: black;
  cursor: pointer;
  background-color: white;
}

.dropdown-menu li:hover {
  background-color: rgba(185, 184, 184);
}

.menu-bar ul li a {
  font-size: 18px;
  color: var(--color-whitee);
  text-decoration: none;
  transition: all 0.3s;
}

.menu-bar ul li a:hover {
  color: var(--color-primary);
}

.fas {
  float: right;
  margin-left: 10px;
  padding-top: 3px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  z-index: 9;
}

.dropdown-menu.open-up {
  left: 5px;
  bottom: 30%;
}

.menu-bar ul li:hover .dropdown-menu {
  display: block;
  left: 5px;
  margin-top: -10px;
  background-color: var(--color-black);
}

.menu-bar ul li:hover .dropdown-menu ul {
  display: block;
  margin: 2px;
}

.menu-bar ul li:hover .dropdown-menu ul li {
  width: 100px;
  padding: 5px;
}

.dropdown-menu-1 {
  display: none;
}

.dropdown-menu ul li:hover .dropdown-menu-1 {
  display: block;
  position: absolute;
  left: 147px;
  top: 0;
  background-color: var(--color-black);
  font-size: 16px;
}
</style>

<script>
import axios from "axios";
import { useVuelidate } from '@vuelidate/core'
import { required } from "@vuelidate/validators";

export default {
  setup() {
    return { v$: useVuelidate() }
  },

  data() {

    return {
      email: "",
      senha: "",
      loading: false,
      error: false,
      showPassword: false,
    };
  },

  validations() {
    return {
      email: { required },
      senha: { required },
    }
  },

  created(){
    if(localStorage.length > 0){
      localStorage.clear()
      location.reload()
    }

  },
  methods: {

    emitApp() {
      this.$emit("atualizarPagina");
    },

    retirarError() {
      this.error = false
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    validacao() {
      try {
        this.loading = true;
        const data = {
          email: this.email,
          senha: this.senha,
        }

        const url = process.env.VUE_APP_API_URL
        axios
          .post(url + '/login', data)
          .then((responde) => {
            this.loading = true;

            if (responde.data.token) {
              localStorage.setItem("token", responde.data.token);
              localStorage.setItem("User", JSON.stringify(responde.data.user))
              if (JSON.stringify(responde.data.user).includes("Data Priority")) {

                this.$store.commit('autorizar');
                this.emitApp()
              }

              this.$router.push("/usuario");

            }
            else {
              this.loading = false;
            }
          })
          .catch((erro) => {
            this.loading = false;
            this.error = true;
          })

      } catch (error) {
        
      }
    },
    validar_envio() {
      this.v$.$validate()
      if (this.v$.$error) {
        alert("Preencha Campos corretamente")
      }
      else {
        this.validacao()

      }
    },
  },
};
</script>

<template>
  <main @keyup.enter="validar_envio()">
    <div class="img-login"></div>
    <div class="form">
      <div class="img">
        <img src="../../DataSendImagens/Logo.svg" alt="" />
      </div>
      <form @keyup="retirarError">
        <p v-if="error" class="input_error" style="text-align: center; font-size: 1.3rem">Login e/ou senha incorretos.</p>
        <div>
          <label for="email">Login da empresa</label>
          <input :class="[{ error: v$.email.$error }]" type="name" id="email" placeholder="Digite seu email"
            v-model="v$.email.$model" />
          <p class="input_error" v-if="v$.email.$error">Campo obrigatório</p>
        </div>
        <div>
          <div style="display: flex;">
            <label for="senha">Senha</label>
          </div>
          <input :type="showPassword ? 'text' : 'password'" id="senha" placeholder="Digite sua senha" 
          :class="[{ error: v$.senha.$error }]" v-model="v$.senha.$model" />
          <p class="input_error" v-if="v$.senha.$error">Campo obrigatório</p>
          <p type="button" @click="togglePasswordVisibility" style="background: none; padding: 5px 5px; width: 30%; color: black; border: none; cursor: pointer;">
            <i :class="showPassword ? 'fa-solid fa-eye-slash' : 'fa-solid fa-eye'"></i>
            {{ showPassword ? 'Ocultar' : 'Mostrar' }}
          </p>
          <div>
          </div>
        </div>
        <span href="http://" style="display:none;  width:100%; text-align: center;">Esqueci minha senha</span>
        <button style="color: white;" :class="[{ validado: !v$.$invalid }]" @click.prevent="validar_envio()">Fazer login</button>
        <div :class="[{ validado: !v$.$invalid }]"></div>
      </form>
      <div class="overlay" v-if="loading"></div>
    </div>
  </main>
</template>
<style scoped>
* {
  margin: 0;
}
.button-exibir{
  color: black;
}
button,
input {
  border: 1px solid #b3b3b3
}


a {
  display: none;
}

.img-login {
  position: fixed;
  height: 100vh;
  width: 60vw;
  margin: 0;
  background: url(../../DataSendImagens/Group177.png) no-repeat;
  background-size: cover;
}

.form {
  position: absolute;
  right: 7.5%;
  bottom: 20%;
  height: 480px;
  width: 300px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-shadow: 1px 1px 2px 2px rgb(217, 217, 217);
  background-color: white;
}

.form input {
  padding: 15px;
  width: 100%;

  border-radius: 4px;
  font-size: 15px;
}

.form label {
  color: #b3b3b3;
}

.form button {
  color: rgb(255, 255, 255);
  border-radius: 5px;
  width: 100%;
  height: 40px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.validado {
  background-color: #2BB542;
  color: white;
}

.error {
  border: 1px solid rgb(255, 108, 108)
}

p {
  color: rgb(255, 108, 108);
  font-size: 0.8rem;
}
</style>
<script>
import axios from 'axios'
import { toHandlers } from 'vue'
const url = process.env.VUE_APP_API_URL

export default {
  name: 'DisparoSeletor',

  watch: {
    EnviarExpediente(newValue) {
      if (newValue) {
        if(this.diaSemana.length > 0){

            this.enviarExpedienteparaBanco()
        } 
      }
    },
  },

  props: {
    EnviarExpediente: Boolean,
    idUsuario: String,
    Prop: Object, 
  },

  data() {
    return {
      inicioExpediente: '08:00',
      fimExpediente: '17:00',

      mostrarPopup: false,
      popup_position_left: null,
      popup_position_top: null,

      listaDia: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sabado',
      ],
      diaSemana: [],
      expediente: [
        [
          { dia_da_semana: 0, horaInicio: '08:00', horaFinal: '12:00' },
          { dia_da_semana: 0, horaInicio: '13:00', horaFinal: '17:00' },
        ],
        [
          { dia_da_semana: 1, horaInicio: '08:00', horaFinal: '12:00' },
          { dia_da_semana: 1, horaInicio: '13:00', horaFinal: '17:00' },
        ],
        [
          { dia_da_semana: 2, horaInicio: '08:00', horaFinal: '17:00' },
          { dia_da_semana: 2, horaInicio: '13:00', horaFinal: '17:00' },
        ],
        [
          { dia_da_semana: 3, horaInicio: '08:00', horaFinal: '12:00' },
          { dia_da_semana: 3, horaInicio: '13:00', horaFinal: '17:00' },
        ],
        [
          { dia_da_semana: 4, horaInicio: '08:00', horaFinal: '12:00' },
          { dia_da_semana: 4, horaInicio: '13:00', horaFinal: '17:00' },
        ],
        [
          { dia_da_semana: 5, horaInicio: '08:00', horaFinal: '12:00' },
          { dia_da_semana: 5, horaInicio: '13:00', horaFinal: '17:00' },
        ],
        [
          { dia_da_semana: 6, horaInicio: '08:00', horaFinal: '12:00' },
          { dia_da_semana: 6, horaInicio: '13:00', horaFinal: '17:00' },
        ],
      ],
    }
  },

  methods: {
    limpaDia() {
      this.diaSemana = []
      this.inicioExpediente = null
      this.fimExpediente = null
    },

    organizarDadosAntesEnviarBanco() {
      let listaObjetos = []
      for (const c of this.diaSemana) {
        listaObjetos.push(this.expediente[c][0])
        listaObjetos.push(this.expediente[c][1])
      }
      const objetoParaEnviarBanco = {
        expediente: listaObjetos,
        idUsuario: this.idUsuario,
      }
      console.log(objetoParaEnviarBanco)
      return objetoParaEnviarBanco
    },

    enviarExpedienteparaBanco() {
      const enviarExpedienteparaBanco = this.organizarDadosAntesEnviarBanco()
      axios
        .post(url + '/expediente', enviarExpedienteparaBanco)
        .then((response) => {
          return response.data
        })

        this.fecharPopup()
    },

    abrirPopup() {
      this.PopupStatus = true
      const botao = document.getElementById('id_botao')
      const posicaoX = botao.getBoundingClientRect().left
      const posicaoY = botao.getBoundingClientRect().top
      this.popup_position_left = posicaoX + 300
      this.popup_position_top = posicaoY - 500
      this.mostrarPopup = true
    },

    fecharPopup() {
      this.mostrarPopup = false
    },

    removerExpediente(){
        this.fecharPopup
    },
    

  },
}
</script>

<template>
    <div    class="botao_popup"
    id="id_botao"
    @click="abrirPopup"
    type="text"
    disabled> Adicionar Disparo</div>
  <main
    class="popup"
    v-if="mostrarPopup"
    :style="{
      top: popup_position_top + 'px',
      left: popup_position_left + 'px',
    }"
  >
    <section class="botoes">
      <span style="font-size: 1.5rem;">Expediente</span>
      <span @click="fecharPopup" style="cursor: pointer; font-size: 1.5rem;"><i class="fa-solid fa-xmark"></i></span>
    </section>
    <table cellspacing="0">
      <thead>
        <td class="dia">
          Dias
          <span v-if="diaSemana.length > 0">( {{ diaSemana.length }} )</span>
        </td>
        <td>1° entrada</td>
        <td>1° saida</td>
        <td>2° entrada</td>
        <td>2° saida</td>
      </thead>
      <tbody v-for="(item, index) in listaDia" :key="index">
        <td class="dia">
          <input
            type="checkbox"
            class="checkbox"
            :id="item + 'checkbox'"
            v-model="diaSemana"
            :value="index"
          />
          <label class="label_checkbox" :for="item + 'checkbox'">{{
            item
          }}</label>
        </td>
        <td>
          <input
            class="time"
            type="time"
            v-model="expediente[index][0].horaInicio"
          />
        </td>
        <td>
          <input
            class="time"
            type="time"
            v-model="expediente[index][0].horaFinal"
          />
        </td>
        <td>
          <input
            class="time"
            type="time"
            v-model="expediente[index][1].horaInicio"
          />
        </td>
        <td>
          <input
            class="time"
            type="time"
            v-model="expediente[index][1].horaFinal"
          />
        </td>
      </tbody>
    </table>
    <section class="botoes">
      <div class="botao_popup" @click="removerExpediente" v-if="this.$route.query.id" style="  background: rgb(255, 108, 108);" >excluir</div>
      <div class="botao_popup" @click="enviarExpedienteparaBanco()" v-if="this.$route.query.id" style=" background: #2bb542;">salvar</div>
    </section>
  </main>
</template>

<style scoped>
.botao_popup {
  width: 100%;
  border: none;
  color: black;
  background-color: white;
  height: 40px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #b3b3b3;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.botoes .botao_popup{
    border: none;
    width: 30%;
    color: white;
}

template {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.popup {
  position: absolute;
  background: white;
  font-size: 1rem;
}

.botoes{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

button{
  width: 80px;
  height: 30px;
  border:none;
  cursor: pointer;
  color: white;
}

.excluir{
  background: red;
}

.salvar{
  background: green;
}

input{
  border: none;
  font-size: 1rem;
}

td {
  padding: 15px;
  align-items: center;
  border-bottom: 1px solid grey;
  overflow-x: visible;
  overflow-y: visible;
  align-items: center;
}

.popup {
  box-shadow: 1px 1px 1px 1px grey;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px
}

.board {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

</style>

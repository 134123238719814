<script>
import ToggleSwitchEmpresa from '../components/empresa/switchEmpresa.vue'
import { fetchDados } from '../components/empresa/empresasConectadasDados'
import empresasConectadas from '../components/empresa/empresasConectadas.vue'
import cadastroEmpresa from './CadastroEmpresa.vue'
import axios from 'axios'
import ConfirmationModalVue from '@/components/Comfirmacao/ConfirmationModal.vue'
import { existsTypeAnnotation, tsImportEqualsDeclaration } from '@babel/types'
import { AlertDataSend } from '@/components/alert'

const url = process.env.VUE_APP_API_URL

const urlSession = process.env.VUE_APP_API_URL_SOCKET

export default {
  nome: "Empresa",
  components: { empresasConectadas, ToggleSwitchEmpresa, cadastroEmpresa, ConfirmationModalVue },
  data() {
    return {
      empresasConectadasDados: null,
      teste: false,
      mostarCadastroEmpresa: false,
      idEmpresaa: null,
      showDeleteConfirmation: false,
      userToDelete: null,
      SelectedIds: [],
      empresaSelecionada: null,
      todos_itens: null,
      selectedRows: [],
      dados: [],
      DadosPesquisa: '',
      estaOrdemAlfabetica: true,
      nomePreenchido: null,
      limiteUsuario: null,
      qtd_usuario: null,
      numero_bot: true,
      contagemUsuario: null,
      selectLine: [],
      sessionCompanyFordelete: null

    }
  },
  async created() {
    this.empresasConectadasDados = await fetchDados()
  },
  computed: {
    filtrarEmpresas() {
      const pesquisa = this.DadosPesquisa.toLowerCase()
      if (pesquisa === '') {
        return this.empresasConectadasDados;
      }
      return this.empresasConectadasDados.filter((empresa) => {
        const nomeEmpresaLowerCase = empresa.nome.toLowerCase();
        return nomeEmpresaLowerCase.includes(pesquisa);
      });
    }

  },
  methods: {
    abrirCadastroEmpresa() {
      this.mostarCadastroEmpresa = true
      this.nomePreenchido = false

    },
    fecharCadastroEmpresa() {
      this.mostarCadastroEmpresa = false
      location.reload()

    },
    editarEmpresa(idEmpresa) {
      this.idEmpresaa = idEmpresa;
      this.nomePreenchido = false;
      this.mostarCadastroEmpresa = true;
    },
    showDeleteModal(index, id_empresa, sessao) {
      this.userToDelete = index;
      this.empresaSelecionada = id_empresa
      this.sessionCompanyFordelete = sessao

      const empresaToDelete = this.empresasConectadasDados.find(emp => emp.id_empresa === id_empresa);
      if (empresaToDelete) {
        empresaToDelete.backgroundColor = 'rgba(92, 90, 90, 0.1)'
      }
      this.showDeleteConfirmation = true;
    },
    deleteUserConfirmed() {
      if (this.selectedRows.length !== 0) {
        for (let i = 0; i < this.selectedRows.length; i++) {
          if (this.selectedRows[i] !== '46d846a51c') {
            this.deleteSessionCompany(this.selectedRows[i]);
          }
        }
        this.selectedRows = [];
        this.showDeleteConfirmation = false;
      } else {
        if (this.empresaSelecionada !== '46d846a51c') {
          this.deleteSessionCompany(this.empresaSelecionada, this.sessionCompanyFordelete);
        }
        this.selectedRows = [];
        this.showDeleteConfirmation = false;
      }
    },
    deleteUserCanceled() {
      const empresaCanceled = this.empresasConectadasDados.find(emp => emp.id_empresa === this.empresaSelecionada);
      if (empresaCanceled) {
        empresaCanceled.backgroundColor = '';
      }
      this.showDeleteConfirmation = false;
    },
    async removerEmpresa(arg) {
      await axios
        .delete(url + '/empresa', {
          data: { idEmpresa: arg },
        })
        .then(() => {
          this.empresasConectadasDados = this.empresasConectadasDados.filter(
            (emp) => emp.id_empresa !== arg
          )
        })
    },

    async deleteSessionCompany(idEmpresa, session) {
      if (session) {

        await axios.delete(urlSession + '/session/logout', {

          data: { session: session }

        }).then(() => {

          this.removerEmpresa(idEmpresa)

        }).catch(erro => AlertDataSend('Não foi possível deletar empresa.'))

      } else {
        this.removerEmpresa(idEmpresa)
      }
    },
    selecionarEmpresa(idEmpresa) {
      const index = this.selectedRows.indexOf(idEmpresa);
      if (index === -1) {
        this.selectedRows.push(idEmpresa);
      } else {
        this.selectedRows.splice(index, 1);
      }
      const empresa = this.empresasConectadasDados.find(emp => emp.id_empresa === idEmpresa);
      if (empresa) {
        empresa.backgroundColor = empresa.backgroundColor ? '' : 'rgba(92, 90, 90, 0.1)';
      }
    },
  },
}
</script>

<template>
  <main class="base">
    <cadastroEmpresa v-if="mostarCadastroEmpresa" @fecharCadastroEmpresa="fecharCadastroEmpresa" :idEmpresa="idEmpresaa"
      :nomePreenchido="nomePreenchido"
      :empresas-conectadas-dados="empresasConectadasDados">
    </cadastroEmpresa>
    <header class="caixaTitulo">
      <div class="caixaTitulo_titulo">Empresas</div>
      <div>
        <button class="caixaTitulo_botao" @click="abrirCadastroEmpresa()">
          <i class="fa-solid fa-plus"></i>
          Adicionar Empresa
          <div class="DicaTexto">Adicionar Nova Empresa</div>
        </button>
      </div>
    </header>
    <ConfirmationModalVue class="modal-style" v-if="showDeleteConfirmation" @confirmed="deleteUserConfirmed"
      @canceled="deleteUserCanceled" :text="'Deseja realmente excluir esta Empresa?'">
    </ConfirmationModalVue>
    <section class="conteudo">
      <div class="tabela">
        <div style="display:flex">
          <div class="icon-style">
            <i class="fa-solid fa-filter"></i>
          </div>
          <input style="margin-left: 17px;" type="text" placeholder="Pesquisar" v-model="DadosPesquisa"
            class="pesquisa" />
          <button class="botaoPesquisa">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <table cellspacing="0">
          <thead>
            <tr>
            </tr>
            <tr class="nomedascolunas">
              <th>Editar</th>
              <th>
                <span>Nome da empresa
                </span>
              </th>
              <th></th>
              <th>Nome da sessão</th>
              <th>Qtd. Usuários</th>
              <th>Número</th>
              <th style="text-align: center;">Status</th>
              <th style="width: 10%; align-items: center">
                <button class="btn_rm" @click.prevent="removerTodos" style="
                    font-size: 1rem;
                    color: #2bb542;
                    font-weight: bold;
                    text-align: center;
                    margin-left: 1.7vw;
                  ">
                  <p>Excluir</p>
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(row, index) in filtrarEmpresas" :key="index">
            <tr class="back-color" :style="{
              'backgroundColor':
                userToDelete && userToDelete.id_empresa === row.id_empresa ? userToDelete.backgroundColor : ''
            }">
              <td class="quina">
                <button class="btn_edit" @click="editarEmpresa(row.id_empresa)">
                  <i class="fa fa-pencil"></i>
                </button>
              </td>
              <td>{{ row.nome }}</td>
              <th></th>
              <td>{{ row.sessao }}</td>
              <td>{{ row.qtd_usuarios_cadastrados }}/{{ row.qtd_usuario }}</td>
              <td :class="{ 'numero-conectado': row.numero_bot, 'sem-numero': !row.numero_bot }">
                <span v-if="row.numero_bot">{{ row.numero_bot }} </span>
                <span v-else>Nenhum número encontrado</span>
              </td>

              <td class="quina">
                <ToggleSwitchEmpresa :IdUnico="row.id_empresa" :conectado="true" :sessao="row.sessao">
                </ToggleSwitchEmpresa>
              </td>
              <td class="quina">
                <button class="btn_edit" style="color: red; font-size: 1rem;"
                  @click="showDeleteModal(index, row.id_empresa, row.sessao)">
                  <i class="fa fa-xmark"></i>
                </button>
              </td>
            </tr>
            <tr class="line">
              <td colspan="8" class="naoSelecionavel">a</td>
            </tr>
          </tbody>
        </table>
        <div v-if="filtrarEmpresas == 0" class="nadaEncontrado">
          <div>
            Nehuma Empresa foi encontrada
            <i class="fa-solid fa-plug-circle-exclamation"></i>
          </div>
        </div>
      </div>
      <!-- <div> 
        <button class="btn-info">
          <i class="fa-solid fa-circle-info"></i>
        </button>
      </div> -->
    </section>
  </main>
</template>


<style scoped>
.tabela {
  width: 100%;
  height: 70vh;
  font-family: Arial, Helvetica, sans-serif;
  overflow-y: scroll;
  overflow-x: scroll;
}

h1 {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.caixaTitulo_botao:hover {
  background-color: #2bb542d7;
}

th,
td {
  padding: 5px;
  border-spacing: 35px;

}

.modal-style {
  margin-left: 28rem;
}

.btn-info {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: green;
  font-size: 1.1rem;
}

.caixaTitulo_botao {
  position: relative;
  display: inline-block;
}

.caixaTitulo_botao:hover .DicaTexto {
  visibility: visible;
}

.icon-style {
  padding-left: 4vh;
}

.DicaTexto {
  position: absolute;
  visibility: hidden;
  font-size: 13px;
  width: 200px;
  background: rgba(37, 37, 37, 0.695);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 4px;
  bottom: 100%;
  left: 50%;
  margin-left: -100px;
  margin-bottom: 5px;
  transition-timing-function: ease;
  transition-duration: 0.2s;
}

.DicaTexto:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(37, 37, 37, 0.695) transparent transparent transparent;
}

.sem-numero {
  color: rgba(92, 90, 90, 0.5);
}

.back-color:hover {
  background-color: rgba(92, 90, 90, 0.1);
}

table {
  width: 100%;
}

thead {
  border-bottom: 1px solid #b3b3b3;
}

tr td {
  border-left: 1px solid #b3b3b3;
}

.nomedascolunas {
  text-align: left;
  color: #2bb542;
  font-family: initial;
}

thead {
  margin-bottom: 5px;
}

.nomedascolunas th {
  border-bottom: 1px solid #2bb542;
}

.pesquisa {
  width: 20%;
  height: 30px;
  padding-left: 5px;
  display: flex;
}

.line {
  padding: 0;
  color: transparent;
  border-left: none;
  border-right: none;
}

.line td {
  border-bottom: 1px solid #b3b3b3;
  border-left: none;
  height: 1px;
  padding: 0;
}

.line_vazia td {
  height: 1px;
  border: none;
}

.quina {
  width: 15px;
  text-align: center;
}


.botaoPesquisa {
  background-color: white;
  padding: 6px;
  border: 0px;
  cursor: pointer;
}

.nadaEncontrado {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  text-align: center;
  color: #c1c1c1;
}


.naoSelecionavel {
  font-size: 2px;
  color: transparent;

  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import { checkToken } from '../stores/auth.js'
import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes,
  
})

router.beforeEach(async (to, from, next) => {
  if (to.meta?.public) {
    next()
  } else if (to.meta?.auth) {
    const auth = localStorage.getItem('token')
    const devAut = localStorage.getItem('vuex')
    if (auth) {
      const isAuthenticated = await checkToken(auth)
      if (isAuthenticated) {
        if (to.meta?.admin) 
        { 
          const devAut = localStorage.getItem('vuex')
          if (devAut) {
            next()
          } else {
            next({ name: 'usuario' })
          }  
        } else {
          next()
        }
      } else {
        next({ name: 'home' })
      }
    } else {
      next({ name: 'home' })
    }
  } else {
    next()
  }
})

export default router

<template>
  <main>
    <div class="container-switch">
      <!-- Caixa de seleção para o Toggle Switch -->
      <input type="checkbox" :id="'toggle-' + IdUnico" class="check" v-model="isChecked" />
      <label :for="'toggle-' + IdUnico" class="toggle-switch" @click=tomarDecisao(IdUnico)>
        <div class="ativo">ON</div>
        <div class="inativo">OFF</div>
      </label>
    </div>
  </main>
</template>

<script>
import axios from 'axios';
import { deleteDados } from './empresasConectadasDados';
const url = process.env.VUE_APP_API_URL_SOCKET
export default {
  name: "ToggleSwitchEmpresa",
  data() {
    return {
      // Estado atual do Toggle Switch
      isChecked: true

    };
  },

  props: {
    // Identificador exclusivo para o Toggle Switch
    IdUnico: {
      required: true,
    },
    sessao: {
      required: true,
    },

    // Estado inicial do Toggle Switch (Conectado ou Desconectado) recebe dados do banco de dados
  },
  async created() {
    const data = { session: this.sessao }

    await axios.post(url + '/session', data).then((response) => {
      this.isChecked = false
    }).catch(() => {
      this.isChecked = true
    })
  },
  methods: {
    encerrarConexao() {
      deleteDados(this.sessao)
    },

    async tomarDecisao(id) {
      if (this.isChecked === false) {
        this.encerrarConexao
        await axios.delete(url+'/session/logout', {
          data: { session: this.sessao }
        }).then(() => {
          this.isChecked = !this.isChecked
        })
      }
      else {
        try {
          this.reconectarConexao
        } catch (error) {
          this.isChecked = !this.isChecked
        }

      }
    },
  }
}
</script>

<style scoped>
main {
  width: 100px;
}

/* Estilos do Toggle Switch */
.toggle-switch {
  width: 100px;
  height: 20px;
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid #b3b3b3;
  background: #b3b3b3;
  transition: background-color 0.3s;
  cursor: pointer;
  font-size: 0.8rem;
}

/* Estilo da caixa de seleção oculta */
.check {
  display: none;
}

/* Estilo da bola que desliza */
.ball {
  position: relative;
  cursor: pointer;
  width: 120px;
  height: 20px;
  border-radius: 15px;
  background-color: transparent;
  transition: transform 0.3s;
  font-size: 1rem;
  font-weight: lighter;
}

/* Estilo para mover a bola quando o Toggle Switch está ligado */
.inativo {
  color: white;
}

.ativo {
  background: white;
}

.ativo,
.inativo {
  width: 50%;
  border-radius: 15px;

  height: 100%;
  padding-top: 2px;

  -webkit-touch-callout: none;
  /* iPhone OS, Safari */
  -webkit-user-select: none;
  /* Chrome, Safari 3 */
  -khtml-user-select: none;
  /* Safari 2 */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  user-select: none;
}

.container-switch input:checked~label .inativo {
  background: white;
  color: black;
}

.container-switch input:checked~label .ativo {
  background: transparent;
  color: white;
}
</style>
